<template>
<b-card class="user-info">
    <b-row>
        <b-col cols="21" xl="5" class="d-flex justify-content-between flex-column">

            <!-- User Avatar & Action Buttons -->
            <div class="d-flex justify-content-start">
                <b-avatar size="104px" variant="light-secondary" rounded :src="profileImageUrl" :text="avatarText(full_name)" />
                <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                        <h4 class="mb-0">
                            {{ full_name }}
                        </h4>
                        <span class="card-text">{{ email }}</span>
                    </div>

                </div>
            </div>

            <!-- User Stats -->
        </b-col>

        <!-- Right Col: Table -->
        <b-col cols="12" xl="7">
            <table class="mt-2 mt-xl-0 w-100 user-info-table">
                <tr>
                    <th class="pb-50 text-nowrap">
                        <feather-icon icon="UserIcon" class="mr-75 hidden-sm" />
                        <span class="font-weight-bold">User Name:</span>
                    </th>
                    <td class="pb-50">
                        {{ full_name }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50 text-nowrap">
                        <feather-icon icon="HashIcon" class="mr-75 hidden-sm" />
                        <span class="font-weight-bold">User Id:</span>
                    </th>
                    <td class="pb-50">
                        {{ userID}}
                    </td>
                </tr>
          
                <tr>
                    <th class="pb-50 text-nowrap">
                        <feather-icon icon="CheckIcon" class="mr-75 hidden-sm" />
                        <span class="font-weight-bold ">Status:</span>
                    </th>
                    <td class="pb-50 text-capitalize text-success">
                        Active
                    </td>
                </tr>
             
                <tr>
             
                </tr>
               
                <tr>
                    <!-- <th class="pb-50 text-nowrap">
                        <feather-icon icon="MapPinIcon" class="mr-75 hidden-sm" />
                        <span class="font-weight-bold">Location:</span>
                    </th> -->
                      <!-- <td class="pb-50 text-capitalize ">
                        {{userCountry}}
                    </td> -->
                    <td class="pb-50">
                   
                    </td>
                </tr>

            </table>
        </b-col>
    </b-row>
</b-card>
</template>

<script>

import {
    avatarText
} from '@core/utils/filter'

export default {
    name: 'ProfileUserInfo',
   
    computed: {
        full_name() {

            return this.$store.state.app.fullName
        },
        email(){
             return this.$store.state.app.userEmail
        },
        userID(){
            return this.$store.state.app.userID
        },
        profileImageUrl() {
            return this.$store.state.app.profileImage
        },
        userCountry(){
            return this.$store.state.app.userCountry
        }
    },

    setup() {
        return {
            avatarText,
        }
    }
}
</script>
