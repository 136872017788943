<template>
<div>
    <b-card class="">
        <b-tabs pills>
            <!-- Tab: Account -->
            <b-tab active>
                <template #title>
                    <span class="d-sm-inline">Account Details</span>
                </template>

                <b-row class="mt-2">
                    <b-col cols="12" md="4" class="mt-50">
                        <b-media no-body vertical-align="center" class="flex-column flex-md-row">
                            <b-media-aside class="mr-2">
                                <b-img ref="refPreviewEl" :src="blogEdit.featuredImage" height="" width="" class="rounded mb-1 mb-md-0 as-upload-image" v-if="
                                user_details.profile_url == '' || user_details.profile_url == null" />

                                <b-img v-else ref="refPreviewEl" :src="user_details.profile_url" height="auto" width="144" class="rounded mb-1 mb-md-0 as-upload-image" />
                            </b-media-aside>
                            <b-media-body>
                                <b-card-text class="m-0">
                                    <b-link id="blog-image-text"> </b-link>
                                </b-card-text>
                                <div class="d-inline-block">
                                    <label class="vs-input--label">Profile Image</label>
                                    <b-form-file ref="refInputEl" v-model="blogFile" accept="image/*" placeholder="Choose file" @input="inputImageRenderer" />
                                </div>
                            </b-media-body>
                        </b-media>

                    </b-col>
                    <b-col md="12" class="mt-50 mb-25">
                        <hr class="mb-2 mt-2">
                    </b-col>
                </b-row>

                <b-form>
                    <b-row>

                        <b-col cols="12" md="3">
                            <b-form-group label="First name" label-for="username">
                                <b-form-input id="username" v-model="first_name" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" md="3">
                            <b-form-group label="Last name" label-for="full-name">
                                <b-form-input id="full-name" v-model="last_name" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" md="4">
                            <b-form-group label="Email" label-for="email">
                                <b-form-input id="email" type="email" disabled v-model="email" />
                            </b-form-group>
                        </b-col>

                        <!-- <b-col cols="12" md="4">
                            <b-form-group label="Mobile" label-for="company">
                                <b-form-input id="company" v-model="mobile_phone" type='number' />
                            </b-form-group>
                        </b-col> -->

                        <!-- <b-col lg="4">
                            <label class="vs-input--label">Gender</label>
                            <div class="demo-inline-spacing">
                                <b-form-radio v-model="gender" name="some-radio9" value="1" class="custom-control-primary">
                                    Male
                                </b-form-radio>
                                <b-form-radio v-model="gender" name="some-radio9" value="2" class="custom-control-primary">
                                    Female
                                </b-form-radio>
                                <b-form-radio v-model="gender" name="some-radio9" value="3" class="custom-control-primary">
                                    Other
                                </b-form-radio>
                            </div>

                        </b-col> -->

                        <!--
                           <b-col lg="4">
                            <label class="vs-input--label">Address</label>
                            <div class="demo-inline-spacing">
                                <b-button @click='showAddress'>Add/Edit Address</b-button>
                            </div>
                        </b-col>
                        -->

                    </b-row>
                    <b-row>

                        <!-- <b-col md="6">
                            <label class="vs-input--label">Location</label>
                            <div class="tab-change-pwd md:ml-4 md:mt-0 mb-2 ml-0 text-center">
                                <vue-google-autocomplete id="from_address" :country="[]" classname="w-full" placeholder="Search Location" v-on:placechanged="getAddressData" class="search-location form-control" v-model="addressFormat"></vue-google-autocomplete>
                            </div>
                        </b-col> -->
                    </b-row>
                </b-form>
                <b-row>
                    <b-col md="4" cols="12" class=''>

                    </b-col>

                    <b-col md="8" cols="12" class='text-right'>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2" @click='ValidationOne()'>
                            Save
                        </b-button>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="mt-2 ml-1" @click='cancelUpdate()'>
                            Cancel
                        </b-button>

                    </b-col>
                </b-row>
            </b-tab>

        </b-tabs>

        <!-- Delete My Account popup -->
        <b-modal id="delete-account" title="Confirmation">
            <div>
                <b-row>
                    <b-col md="12">
                        <h5 class="my-1">Are you sure you want to delete your account and delete all your data? </h5>
                    </b-col>
                </b-row>
            </div>
            <template v-slot:modal-footer="{}">
                <b-button variant="primary" @click="deleteAccount()">Yes</b-button>
                <b-button variant="danger" @click="closeAccountDelete()">No</b-button>
            </template>
        </b-modal>

    </b-card>

</div>
</template>

<script>
import {
    BContainer,
    BTab,
    BFormRadio,
    BInputGroup,
    BInputGroupPrepend,
    BTabs,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BImg,
    BCard,
    BAlert,
    BLink,
    BButton,
    BMedia,
    BFormRadioGroup,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormFile,
    BTable,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BModal,
    VBModal,
} from "bootstrap-vue";

// import {
//     avatarText
// } from "@core/utils/filter";
import vSelect from "vue-select";
import {
    useInputImageRenderer
} from "@core/comp-functions/forms/form-utils";
import {
    ref
} from "@vue/composition-api";

import VueGoogleAutocomplete from "vue-google-autocomplete";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
export default {
    components: {
        BContainer,
        flatPickr,
        BInputGroup,
        BInputGroupPrepend,
        BCol,
        BTab,
        BTabs,

        BAlert,
        BLink,
        BButton,
        BFormRadio,
        BMedia,

        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormFile,
        BForm,
        BTable,
        BCard,
        BCardHeader,
        BCardTitle,
        BFormCheckbox,
        vSelect,
        BFormRadioGroup,
        BModal,
        VBModal,

        BAvatar,
        BMediaAside,
        BMediaBody,
        BImg,

        VueGoogleAutocomplete,
    },
    directives: {
        Ripple,
        'b-modal': VBModal,
    },
    setup() {
        const refInputEl = ref(null);
        const refPreviewEl = ref(null);

        const {
            inputImageRenderer
        } = useInputImageRenderer(
            refInputEl,
            (base64) => {
                refPreviewEl.value.src = base64;
            }
        );

        return {
            refInputEl,
            refPreviewEl,
            inputImageRenderer,
        };
    },
    data() {
        return {

            user_details: [],
            blogFile: null,
            blogEdit: {},
            first_name: "",
            last_name: "",
            email: "",
            company: "",
            user_status: "",

            mobile_phone: "",
            gender: "",

            unit_no: '',
            street_no: '',
            street_name: '',
            suburb: '',
            city: '',
            state: '',
            country: '',
            postal_code: '',
            Selected: '',
            Selected1: '',
            addressFormat1: '',
            addressFormat: '',
            addressData: '',
            userData: [],
            isMain: false,
            address_id: 0,
            address_id2: 0,
            user_id: '',

            shippingAddress: '',
            mainAddress: '',

        };
    },
    created() {
        // this.$http.get("/blog/list/data/edit").then((res) => {
        //     this.blogEdit = res.data;
        // });
        this.getUserDetailsByID();

    },
    methods: {
        async getUserDetailsByID() {

            let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;
            // const api_key = JSON.parse(localStorage.getItem("domain_Data")).api_key;

            var myHeaders = new Headers();

            const userData = localStorage.getItem("userdetails")

            if (userData == null || undefined) {
                this.user_id = null
            } else {
                this.user_id = JSON.parse(localStorage.getItem("user")).user_id;
            }

            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            // myHeaders.append('x-api-key', api_key);

            var urlencoded = new URLSearchParams();

            urlencoded.append("tenant_id", tenant_id);
            urlencoded.append("user_id", this.user_id);
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow",
            };

            const rawResponse = await fetch(

                process.env.VUE_APP_BASE_URL + "profile",
                requestOptions
            );

            const response = await rawResponse.json();

            this.user_details = response.data[0]
            this.first_name = this.
            this.last_name = this.user_details.last_name
            this.email = this.user_details.email
            this.company = this.user_details.company
            this.gender = this.user_details.gender
            this.mobile_phone = this.user_details.mobile_phone
            if (response.address) {
                this.addressFormat = response.address[0].address
                this.city = response.address[0].city == 'null' ? "" : response.address[0].city
                this.country = response.address[0].country
                this.postal_code = response.address[0].pin_code == 'null' ? "" : response.address[0].pin_code
                this.state = response.address[0].state
                this.street_name = response.address[0].street_name
                this.street_no = response.address[0].street_number == "null" ? "" : response.address[0].street_number
                this.suburb = response.address[0].suburb
                this.unit_no = response.address[0].unit_no == "null" ? "" : response.address[0].unit_no
                this.address_id = response.address[0].address_id
            }

            // this.$store.commit("app/SET_PROFILE_URL", response.data[0].profile_url);
            // localStorage.setItem('user_profile', response.data[0].profile_url);

            // this.$store.commit("app/SET_FULL_NAME", response.data[0].full_name);
            // this.$store.commit("app/SET_USER_ROLE", response.data[0].user_role);
            // this.$store.commit("app/SET_USER_EMAIL", response.data[0].email);
            // this.$store.commit("app/SET_USER_COUNTRY", response.data[0].country);
            // this.$store.commit("app/SET_USER_ID", response.data[0].user_id);

            // this.$store.commit("app/SET_USER_AGE", response.data[0].age);
            // this.$store.commit("app/SET_USER_GENDER", response.data[0].gender_name);
            // this.$store.commit("app/SET_USER_HEIGHT", response.data[0].height);
            // this.$store.commit("app/SET_USER_WEIGHT", response.data[0].weight);
            // this.$store.commit("app/SET_USER_FEATURE", response.data[0].is_user_features_required);

        },

        ValidationOne() {

            if (this.first_name == "" || this.first_name == null) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Please enter first name',
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                    },
                })
                return false;
            } else if (this.last_name == "" || this.last_name == null) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Please enter last name',
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                    },
                })
                return false;
            } else {
                this.insertUserDetails();
            }
        },
        async insertUserDetails() {

            var mailFormat = /^([^@\s]+)@([^@\s]+)\.([^@\s]+)$/;
            if (!this.email.match(mailFormat)) {
                this.$bvToast.toast('Please enter valid email', {
                    title: `Invalid Email address`,
                    variant: 'danger',
                    solid: true,
                });
                return false;
            } else {

                var myHeaders = new Headers();
                let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;;
                const userData = localStorage.getItem("userdetails")

                if (userData == null || undefined) {
                    this.user_id = null
                } else {
                    this.user_id = JSON.parse(localStorage.getItem("userdetails")).user_id;
                }

                // const api_key = JSON.parse(localStorage.getItem("domain_Data")).api_key;
                // myHeaders.append('x-api-key', api_key);
                const formData = new FormData();
                formData.append("tenant_id", tenant_id);
                formData.append("user_id", this.user_id);
                formData.append("user_name", this.email);
                formData.append("email", this.email);
                formData.append("first_name", this.first_name);
                formData.append("last_name", this.last_name);
                formData.append("mobile_phone", this.mobile_phone);

                formData.append("gender", this.gender);
                formData.append("title", null);

                formData.append("updatedby", this.user_id);
                formData.append("company", this.company);
                formData.append("user_account_status", 1);

                if (this.blogFile == null) {
                    formData.append("uploads[]", this.blogFile);
                } else {

                    let file_type = this.blogFile.type;
                    if (file_type.includes("/")) {
                        const filetypEArray = file_type.split("/");
                        let fileformat = filetypEArray[0];

                        if (fileformat !== 'image') {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Please upload image as profile picture',
                                    icon: 'AlertIcon',
                                    variant: 'danger',
                                },
                            })
                            return false;
                        } else {
                            formData.append("uploads[]", this.blogFile);
                        }

                    }
                }

                formData.append("user_role", '');

                formData.append(
                    "address_list",
                    JSON.stringify([{
                            address_id: this.address_id,
                            address: this.addressFormat,
                            unit_no: this.unit_no,
                            street_name: this.street_name,
                            street_number: this.street_number,
                            suburb: this.suburb,
                            country: this.country,
                            city: this.city,
                            state: this.state,
                            pin_code: this.pin_code,
                            address_type: 1,
                            main_address_type: this.Selected == '1' ? 1 : 0,

                        },
                        // {
                        //       address_id: this.address_id,
                        //     address: this.address,
                        //     unit_no: this.unit_no,
                        //     street_name: this.street_name,
                        //     street_number: this.street_number,
                        //     suburb: this.suburb,
                        //     country: this.country,
                        //     city: this.city,
                        //     state: this.state,
                        //     pin_code: this.pin_code,
                        //     address_type: 1,
                        //     main_address_type: this.Selected == '2' ? 1 : 0,
                        // }
                    ])
                );
                var requestOptions = {
                    method: "POST",
                    body: formData,
                    headers: myHeaders,
                };
                const rawResponse = await fetch(

                    process.env.VUE_APP_BASE_URL + "user/insertUpdateUser",
                    requestOptions
                );
                const response = await rawResponse.json();

                if (response.data[0].status == 1) {

                    this.getUserDetailsByID()

                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'User updated successfully',
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })

                } else {

                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'User failed to update',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    })
                }
            }

        },
        cancelUpdate() {
            this.$router.push("/index")
        },

        cancelEdit() {
            this.$bvModal.hide('edit-address')
        },
        showAddress() {
            this.$bvModal.show('edit-address')
        },
        getAddressData: function (addressData, placeResultData) {
            console.log(placeResultData)

            this.street_no = addressData.street_number;
            this.street_name = addressData.route;
            this.suburb = addressData.locality;
            this.postal_code = addressData.postal_code;
            this.state = addressData.administrative_area_level_1;
            this.country = addressData.country;
            this.addressFormat = placeResultData.formatted_address;
        },
        async getUserAddressDetailsList() {
            var myHeaders = new Headers();
            // let authentication = localStorage.getItem("tokken");
            const userData = localStorage.getItem("userdetails")

            if (userData == null || undefined) {
                this.user_id = null
            } else {
                this.user_id = JSON.parse(localStorage.getItem("userdetails")).user_id;
            }
            let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;;
            // myHeaders.append("x-access-token", authentication);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            // const api_key = JSON.parse(localStorage.getItem("domain_Data")).api_key;
            // myHeaders.append('x-api-key', api_key);

            var urlencoded = new URLSearchParams();

            urlencoded.append("tenant_id", tenant_id);
            urlencoded.append("user_id", this.user_id);
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow",
            };

            const rawResponse = await fetch(

                process.env.VUE_APP_BASE_URL + "user/getUserAddressDetailsList",
                requestOptions
            );

            const response = await rawResponse.json();
            console.log(response)

            this.mainAddress = response.data[0]
            this.shippingAddress = response.data[1]

        },
        editMainAddress() {

            this.$bvModal.show("edit-address");
            this.addressFormat = this.mainAddress.address;
            this.unit_no = this.mainAddress.unit_no;
            this.street_no = this.mainAddress.street_number;
            this.street_name = this.mainAddress.street_name;
            this.suburb = this.mainAddress.suburb;
            this.country = this.mainAddress.country;
            this.state = this.mainAddress.state;
            this.city = this.mainAddress.city;
            this.postal_code = this.mainAddress.pin_code;
            this.address_id = this.mainAddress.address_id
            this.isMain = true;

        },
        editshippingAddress() {
            this.$bvModal.show('edit-address')

            this.isMain = false;
            this.addressFormat = this.shippingAddress.address == null ? '' : this.shippingAddress.address
            this.unit_no = this.shippingAddress.unit_no == null ? '' : this.shippingAddress.unit_no;
            this.street_no = this.shippingAddress.street_number == null ? '' : this.shippingAddress.street_number;
            this.street_name = this.shippingAddress.street_name == null ? '' : this.shippingAddress.street_name;
            this.suburb = this.shippingAddress.suburb == null ? '' : this.shippingAddress.suburb;
            this.country = this.shippingAddress.country == null ? '' : this.shippingAddress.country;
            this.state = this.shippingAddress.state == null ? '' : this.shippingAddress.state;
            this.city = this.shippingAddress.city == null ? '' : this.shippingAddress.city;
            this.postal_code = this.shippingAddress.address == null ? '' : this.shippingAddress.pin_code;
            this.address_id2 = this.shippingAddress.address_id
        },
        async insertUpdateAddress() {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var urlencoded = new URLSearchParams();
            // const api_key = JSON.parse(localStorage.getItem("domain_Data")).api_key;
            //  myHeaders.append('x-api-key', api_key);
            const userData = localStorage.getItem("userdetails")

            if (userData == null || undefined) {
                this.user_id = null
            } else {
                this.user_id = JSON.parse(localStorage.getItem("userdetails")).user_id;
            }

            urlencoded.append("user_id", this.user_id);
            urlencoded.append("unit_no", this.unit_no);
            urlencoded.append("street_number", this.street_no == undefined ? '' : this.street_no);
            urlencoded.append("street_name", this.street_name);
            urlencoded.append("suburb", this.suburb);
            urlencoded.append("address", this.addressFormat);
            urlencoded.append("country", this.country);
            urlencoded.append("city", this.city);
            urlencoded.append("state", this.state);
            urlencoded.append("pin_code", this.postal_code);
            urlencoded.append("address_type", this.isMain == true ? 1 : 2);
            urlencoded.append("main_address_type", this.isMain == true ? 1 : 0);
            urlencoded.append("updatedby", this.user_id);
            urlencoded.append("address_id", this.isMain == true ? this.address_id : this.address_id2);

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow"
            };
            const rawResponse = await fetch(

                process.env.VUE_APP_BASE_URL + "user/insertUpdateUserAddress",
                requestOptions
            );
            const response = await rawResponse.json();
            if (response.data[0].status == 1) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Address updated successfully',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                })
                this.$bvModal.hide('edit-address')
                //    this.$bvModal.hide('edit-shipping-address')
                this.getUserDetailsByID();
                this.getUserAddressDetailsList();

            }
        },

        async deleteAccount() {
            this.isLoading = true;
            var myHeaders = new Headers();

            let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;
            // const api_key = JSON.parse(localStorage.getItem("domain_Data")).api_key;

            const userData = localStorage.getItem("userdetails")

            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            //myHeaders.append('x-api-key', api_key);

            var urlencoded = new URLSearchParams();

            if (userData == null || undefined) {
                this.user_id = null
            } else {
                this.user_id = JSON.parse(localStorage.getItem("userdetails")).user_id;
            }

            urlencoded.append("user_id", this.user_id);
            urlencoded.append("tenant_id", tenant_id);

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow",
            };

            const rawResponse = await fetch(

                process.env.VUE_APP_BASE_URL + "accountDeletion",
                requestOptions
            );
            const response = await rawResponse.json();

            // localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)

            // localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

            this.$store.commit("app/SET_REMOVE_ITEMS");

            this.$store.commit("app/SET_USER", false);

            this.$router.push('/index')

        },

        closeAccountDelete() {
            this.$bvModal.hide("delete-account");
        },

    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.vertical-steps.vue-form-wizard .wizard-card-footer {
    margin-left: 194px !important;
}

.demo-inline-spacing>* {
    margin-top: 0.5rem;
}

.pac-container {
    z-index: 10000 !important;
}

.search-location {
    display: block;
    margin: 0 auto;
    font-size: 15px;
    font-weight: 200;
    outline: none;
    // height: 25px;
    //width:700px;
    width: 100%;
    line-height: 30px;
    text-align: center;
}

#submit {
    float: right;
}

.as-upload-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
}
</style>
