<template>
<b-card no-body class="border-primary">
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">

        <h5 class="mb-0" v-if='user_details.user_role =="member"'>Current Plan</h5>
        <b-badge variant="light-primary" v-if='user_details.user_role =="member"'> {{user_details.membership_type_name}} </b-badge>
        <b-badge variant="light-primary" v-if='user_details.user_role !=="member"'> Customer</b-badge>

    </b-card-header>

    <b-card-body>
        <ul class="list-unstyled my-1" v-if='user_details.user_role !== "member"'>
            <!-- <li>
          
                <span class="align-middle">{{this.user_details.enable_access_to_community==1?'Access to Communities':''}}</span>
            </li> -->
            <li>
                <span class="spaced-item align-middle"> - Full access to customer benefits</span>
            </li>
            <li>
                <span class="spaced-item align-middle"> - Access to both Free & Paid items</span>
            </li>
            <li>
                <span class="spaced-item align-middle"> - AI Chatbot service for Q&A</span>
            </li>
            <li>
                <span class="spaced-item align-middle"> -  Priority customer service</span>
            </li>
        </ul>
        <ul  v-if='user_details.user_role == "member"'>
            <li class="my-25">
                <span class="align-middle">{{user_details.enable_discount_in_events==1?'Discounted events':''}}</span>
            </li>
            <li>
                <span class="align-middle">{{user_details.enable_discount_in_products==1?'Discounted products':""}}</span>
            </li>
            <li>
                <span class="align-middle">{{user_details.enable_membership_only_contents==1?'Membership Only Contents':''}}</span>
            </li>
        </ul>
        <b-button v-if='user_details.user_role !=="nonmember"' v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block @click='upgradePlan()'>
            Upgrade Plan
        </b-button>
        <b-button v-else v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block @click='upgradePlan()'>
            Purchase Membership
        </b-button>
    </b-card-body>
</b-card>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
    directives: {
        Ripple,
    },
    components: {
        BCard,
        BCardHeader,
        BCardBody,
        BBadge,
        BButton,
    },
    data() {
        return {
            user_details: [],
            user_id:''
        }
    },
    created() {
        this.getUserDetailsByID();
    },
    methods: {
        upgradePlan() {
            this.$router.push('/plans')
        },
        async getUserDetailsByID() {
            var myHeaders = new Headers();
            // const api_key = JSON.parse(localStorage.getItem("domain_Data")).api_key;

            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            // myHeaders.append('x-api-key', api_key);

            let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;
            const userData = localStorage.getItem("userdetails")

            if (userData == null || undefined) {
                this.user_id = null
            } else {
                this.user_id = JSON.parse(localStorage.getItem("userdetails")).user_id;
            }

            var urlencoded = new URLSearchParams();

            urlencoded.append("tenant_id", tenant_id);
            urlencoded.append("user_id", this.user_id);
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow"
            };

            const rawResponse = await fetch(

                process.env.VUE_APP_BASE_URL + "user/getUserDetailsById",
                requestOptions
            );

            const response = await rawResponse.json();

            this.user_details = response.data[0];

        }

    }
}
</script>

<style scoped>

.spaced-item {
      display: inline-block;
    margin-bottom: 0px;/* adjust this value to control the spacing */
}
.align-middle {
    /* your styles for vertical alignment or other properties */
}

</style>
